import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";

// import en from "./en/common.json";
// import zh from "./zh/common.json";
// import ru from "./ru/common.json";
// import ja from "./ja/common.json";
// import fr from "./fr/common.json";
// import zhTW from "./zhTW/common.json";
// import de from "./de/common.json";
// import es from "./es/common.json";
// import id from "./id/common.json";

i18n
  .use(HttpBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: "/i18n/{{lng}}/common.json", // 指定加载路径
    },
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;