import {
  Modal, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Image,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import FIREBASE_APP from "../firebase/config";
import { getAuth, signInWithPopup, browserLocalPersistence, GoogleAuthProvider } from "firebase/auth";
import { toast } from "react-toastify";
import { ToastConfig } from "../static/toastify/toastConfig";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


interface SignInWindowProps {
  isOpen: boolean,
  onClosed: () => void,
}

const provider = new GoogleAuthProvider();

export default function SignInWindow (props: SignInWindowProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [isModalOpen, setIsModalOpen] = useState(false);


  // Listen to inside close event
  const handleOpenChange = (isOpen:boolean) => {
    setIsModalOpen(isOpen);
    props.onClosed();
  }

  // Listen to outside open event
  useEffect(() => {
    setIsModalOpen(props.isOpen);
  }, [props.isOpen]);

  // const currentLang = useSelectedLanguage().lang;

  const signIn = () => {
    // alert("Comming soon...");
    // return;
    const auth = getAuth(FIREBASE_APP);
    signInWithPopup(auth, provider)
    .then((result) => {
      setIsModalOpen(false);
      navigate("./canvas");
    }).catch((error) => {
      toast.error(t("alert.signin_fail"), ToastConfig);
    });

    auth.setPersistence(browserLocalPersistence);
  }

  const renderModalContent = () => {
    return (
      <>
        <ModalBody>
          <div className="text-center">
            <div className="text-[64px] font-bold">👨🏻‍💻</div>
            <div className="text-lg md:text-xl font-bold">{t("signin.title")}</div>
            <div className="text-sm md:text-md opacity-70 mt-1 mb-2">{t("signin.subtitle")}</div>
          </div>

          <div className="w-full flex justify-center mb-2">
            <div className="cursor-pointer w-72 drop-shadow opacity-95 hover:opacity-100 hover:scale-[1.01] hover:drop-shadow-2xl transition z-10">
              <Image onClick={signIn} src="./assets/icons/google-signin.png" />
            </div>
          </div>

        </ModalBody>
        <ModalFooter>

        </ModalFooter>
      </>
    )
  }

  return (
    <>
      <Modal isOpen={isModalOpen} onOpenChange={handleOpenChange} size="lg">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1"></ModalHeader>
              {renderModalContent()}
            </>
          )}
          </ModalContent>
      </Modal>
    </>
  )
}