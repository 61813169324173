'use client'
import { AuthInfoProps, BillCurrency } from '../static/types';
import {Tooltip, Image, Button, Link} from "@nextui-org/react";
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PaymentWindow from './paymentWindow';
import EventBus from '../bus';
import { getUserPaymentInfo } from '../firebase/funcs';
import { LINKER_REF } from '../pages/linker';
import SignInWindow from './signInWindow';
import { LS_KEY_LANGSET } from '../static/localstorageState';
import { EVTBUS_KEY_UPDATE_CREDITS } from '../static/eventBusState';

// import type { RootState } from '@/store/store'; 
import { useDispatch } from 'react-redux';
import { setStoreUserCurrency } from '../store/features/currency/currencySlice';
import { MotionRevealUp } from "./animated-text";
import { useMotionValueEvent, useScroll } from "framer-motion";

interface NavBarProps {
  auth: AuthInfoProps,
  isAuthing: boolean,
  productHuntLink: string,
}


export default function Navbar (props:NavBarProps) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const { scrollY } = useScroll();
  const [isScrolledNav, setIsScrolledNav] = useState(true);

  // useMotionValueEvent(scrollY, "change", (latest) => {
  //   setIsScrolledNav(latest > 0.05);
  // });
  // const windowSize = useWindowSize();

  // const userCurrency = useSelector((state: RootState) => state.userCurrency.value)
  const dispatch = useDispatch();

  const [isPaymentWindowOpen, setIsPaymentWindowOpen] = useState<boolean>(false);
  // Sign in window
  const [isSigninWindowOpen, setIsSigninWindowOpen] = useState<boolean>(false);

  const [userCredits, setUserCredits] = useState<number | null>(-1);

  const isCanvasPage = location.pathname === "/canvas";
  // const isInspirePage = location.pathname === "/inspire";
  // const isSketchPage = location.pathname === "/sketch";
  const isWorkingPage = isCanvasPage;

  const switchLang = (lang: string) => {
    setSearchParams({ lang });
  };
  
  // Receive event for update credits
  useEffect(() => {
    EventBus.on(EVTBUS_KEY_UPDATE_CREDITS, () => {
      // wait for 2 seconds, for more credits
      const getCreditsTimer = setTimeout(async () => {
        const credits = await getUserPaymentInfo();
        dispatch(setStoreUserCurrency(credits.currency))
        setUserCredits(credits.credits);
      }, 2200);

      return () => clearTimeout(getCreditsTimer);
    });

    // Switch lang if user select a lang
    if(localStorage.getItem(LS_KEY_LANGSET)) {
      switchLang(localStorage.getItem(LS_KEY_LANGSET) as string);
    }
  }, []);

  useEffect(() => {
    setUserCredits(props.auth.userCredits);
    // If invalid than back to USD
    dispatch(setStoreUserCurrency(BillCurrency[props.auth.userCurrency as keyof typeof BillCurrency] || BillCurrency.usd));
  }, [props.auth.userCredits])

  const handleClosePaymentWindow = () => {
    setIsPaymentWindowOpen(false);
    EventBus.emit("display-feedback-window-on-first", true);
  }

  const topup = async () => {
    setIsPaymentWindowOpen(true);
    // await topupCredits();
  }

  const renderNavItem = (i18nKey: string, link: string, isSelected: boolean = false, isExternal: boolean = false) => {
    return (
      <div className={`rounded-full px-4 py-1.5 ${isSelected ? 'bg-gray-50/10 shadow-lg font-bold' : 'bg-transparent bg-opacity-0 font-normal'}`}>
        <Link
          color="foreground"
          href={link}
          isExternal={isExternal}
          showAnchorIcon={isExternal}
          className="transition whitespace-nowrap text-sm">
            {t(i18nKey)}
        </Link>
      </div>
    )
  }

  // Check if is user center, if so, do not need credits components
  const isUserCenter = location.pathname === "/userCenter";
  const isHomePage = location.pathname === "/";
  // const isMobile = useWindowSize().width < 768;
  const shouldDisplayCreditsInfo = !isUserCenter && !isHomePage;
  const shouldDisplayNav = !isHomePage && props.auth.isAuth;

  return (
    <div className="navbar flex w-full items-center justify-between z-50">
      <header
        className={`fixed w-full px-2 md:px-0 transition-all duration-1000 ease-out z-50 top-0 left-1/2 -translate-x-1/2 ${
          isScrolledNav ? "mt-4" : "mt-6"
        }`}
      >
        <nav
          className={`flex justify-between w-full ml-auto mr-auto transition-all duration-1000 py-2 ${
            isScrolledNav
              ? "pl-5 md:pl-7 pr-2 md:pr-4 md:max-w-6xl bg-[rgba(31,32,35,.85)] bg-opacity-70 backdrop-blur-lg border-opacity-100 rounded-full border-gray-500"
              : `md:max-w-full px-6 md:px-32 border-opacity-0`
          }`}
        >
          {/** Nav item - left: Logo */}
          <div className="flex gap-8 items-center">
            <MotionRevealUp className="md:pt-1">
              <Link href="/">
                {/* PC */}
                <Image
                  className="hidden md:block"
                  src={!isScrolledNav ? "/assets/img/logo.png" : "/assets/img/logo-single.png"}
                  alt="Building AI Logo"
                  radius="none"
                  width={!isScrolledNav ? 160 : 32}
                />

                {/* Mobile */}
                <Image
                  className="block md:hidden mb-0.5"
                  src={!isScrolledNav ? "/assets/img/logo.png" : "/assets/img/logo-single.png"}
                  alt="Building AI Logo"
                  radius="none"
                  width={!isScrolledNav ? 160 : 28}
                />
              </Link>
              
            </MotionRevealUp>

            <div className="hidden md:flex gap-1 items-center justify-between">
              { renderNavItem("navbar.link_renderer", "/canvas", isCanvasPage)}
              { shouldDisplayNav && renderNavItem("Interior AI", "https://interior-render.com/" + LINKER_REF, false, true)}
            </div>

          </div>

          <div className="md:flex justify-end items-center">
            {/** Nav item - right: product hunt */}
            <div className={`mt-0.5 w-[20px] md:w-auto mr-4 ${isScrolledNav ? 'opacity-30' : 'opacity-0'} hover:opacity-100 text-white transition`}>
              <a
                href={props.productHuntLink}
                target="_blank"
                className="no-underline text-xs hidden md:block">
                  <div className="flex items-center justify-center">
                    {/* { windowSize.width >= 768 ? <div>{t("navbar.producthunt")}</div> : null } */}

                    <div className="ml-0 md:ml-1.5 h-5 md:h-4">
                      <svg className="w-full h-full" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M657.142857 435.428571q0 32-22.571429 54.285714t-54.571429 22.285714l-144.571429 0 0-153.714286 144.571429 0q32 0 54.571429 22.571429t22.571429 54.571429zm102.285714 0q0-74.285714-52.285714-126.857143t-127.142857-52.571429l-247.428571 0 0 512 102.857143 0 0-153.714286 144.571429 0q74.285714 0 126.857143-52.285714t52.571429-126.571429zm264.571429 76.571429q0 104-40.571429 198.857143t-109.142857 163.428571-163.428571 109.142857-198.857143 40.571429-198.857143-40.571429-163.428571-109.142857-109.142857-163.428571-40.571429-198.857143 40.571429-198.857143 109.142857-163.428571 163.428571-109.142857 198.857143-40.571429 198.857143 40.571429 163.428571 109.142857 109.142857 163.428571 40.571429 198.857143z" p-id="2363" fill="#ffffff"></path></svg>
                      {/* <img src="./img/ph-logo.svg" alt="product hunt logo" className="w-full h-full" /> */}
                    </div>
                    
                  </div>
                  
              </a>
            </div>
            
            {/** Nav item - right: User Center and top-up */}
            <div
              className={`flex justify-end items-center gap-3 text-sm md:text-base py-1 px-1 transition-all rounded-full ${
                !isScrolledNav && isWorkingPage ? "bg-gray-400/10 backdrop-blur-md px-4 py-2" : "bg-gray-400/0"
              }`}
            >
              {(props.auth.isAuth && shouldDisplayCreditsInfo) && 
                (<Tooltip content={t("navbar.topup")}>
                  <div
                    className="w-full max-w-[120px] flex items-center align-middle justify-start text-xs h-7 rounded-[100px] bg-zinc-900 hover:bg-zinc-800 border border-zinc-800 hover:border-blue-700 transition cursor-pointer"
                    onClick={topup}>

                    <div className="flex items-center align-middle justify-center w-8 h-full credit-status-add-icon bg-indigo-600 border border-indigo-700 rounded-[100px]">
                      <img className="w-[10px]" src="/assets/icons/plus.svg" alt="add credits" />
                    </div>

                    <div className="w-full text-center">
                      <div>Credits: { userCredits === -1 ? '**' : userCredits }</div>
                    </div>
                  </div>
                </Tooltip> 
              )}

              {props.auth.isAuth && shouldDisplayCreditsInfo && (<div className="cursor-pointer flex justify-start items-center">
                <Link href="/helpCenter" className='opacity-60 hover:opacity-100 transition'>
                  <Tooltip content="Get Help" placement="bottom">
                    <Image
                      src="/assets/icons/question.svg"
                      alt="Help center"
                      width={28}
                      height={28}
                    />
                  </Tooltip>
                </Link>
              </div>)}

              {props.auth.isAuth && (<div className="cursor-pointer flex justify-start items-center">
                {/* <div className="mr-4">
                  <Button radius="full" size="sm">Dashboard</Button>
                </div> */}
                <Link href="/userCenter">
                  <Tooltip content={props.auth.userAll?.email} placement="bottom">
                    <Image
                      src={ props.auth.userImage || "/assets/img/profileph.png" }
                      alt="User profile image"
                      width={36}
                      height={36}
                      className="rounded-full overflow-hidden"
                    />
                  </Tooltip>
                </Link>
              </div>)}

              {!props.auth.isAuth && (
                <div>
                  <Button
                  onClick={() => setIsSigninWindowOpen(true)}
                  color="default"
                  radius="full"
                  isLoading={props.isAuthing}>
                    { props.isAuthing ? t("navbar.checking_auth") : t("navbar.signin") }
                </Button>
              </div>
              )}
            </div>
          </div>
        </nav>
      </header>

    
      <SignInWindow isOpen={isSigninWindowOpen} onClosed={() => setIsSigninWindowOpen(false)}></SignInWindow>

      <PaymentWindow isRanOut={false} isOpen={isPaymentWindowOpen} onClosed={handleClosePaymentWindow}/>

    </div>
  )
}

// Get window size and render by it with resize handler inplace
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: 1920,
    height: 1080,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}