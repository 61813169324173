const sampleAppCode = `
import React from 'react';

const App = () => {
  const invoices = [
    { id: '001', customer: 'SpaceX', date: 'Mar 27, 2017', amount: '$3719.92', status: 'Paid' },
    { id: '002', customer: 'OpenAI', date: 'Mar 27, 2017', amount: '$3719.92', status: 'Paid' },
    { id: '003', customer: 'Apple', date: 'Mar 27, 2017', amount: '$3719.92', status: 'Unpaid' },
    { id: '004', customer: 'Microsoft', date: 'Mar 27, 2017', amount: '$3719.92', status: 'Paid' },
    { id: '005', customer: 'Bytedance', date: 'Mar 27, 2017', amount: '$3719.92', status: 'Pending' },
  ];

  return (
    <div className="bg-gray-800 min-h-screen text-white">
      <div className="flex">
        <aside className="w-64 p-6 space-y-6">
          <div className="text-white font-bold">Dashboard</div>
          <div className="bg-gray-700 p-2">Invoices</div>
          <div>Reports</div>
          <div>Customers</div>
          <div>Settings</div>
          <div>Help</div>
        </aside>
        <main className="flex-1 p-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-xl font-bold">Invoices</h1>
            <div className="flex items-center">
              <input className="input input-bordered w-full max-w-xs" type="text" placeholder="Search invoices" />
              <button className="btn btn-primary ml-2">Add invoice</button>
              <div className="avatar placeholder ml-4">
                <div className="bg-neutral-focus text-neutral-content rounded-full w-12">
                  <span className="text-xl">U</span>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Customer</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((invoice) => (
                  <tr key={invoice.id}>
                    <td>{invoice.id}</td>
                    <td>{invoice.customer}</td>
                    <td>{invoice.date}</td>
                    <td>{invoice.amount}</td>
                    <td>{invoice.status}</td>
                    <td>
                      <button className="btn btn-sm">View</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
};

export default App;
`;

export const daisyuiConfig = {
  template: "vite-react",
  customSetup: null,
  files: {
    "/index.html": {
      code: `
        <!DOCTYPE html>
        <html lang="en">
          <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Vite App</title>
            <link href="https://cdn.jsdelivr.net/npm/daisyui@4.6.0/dist/full.min.css" rel="stylesheet" type="text/css" />
            <script src="https://cdn.tailwindcss.com"></script>
          </head>
          <body>
            <div id="root"></div>
              <script type="module" src="/index.jsx"></script>
          </body>
        </html>
      `,
      readOnly: true,
    },
    "/App.jsx": {
      code: sampleAppCode,
      readOnly: true,
    },
    "/styles.css": {
      code: `
        body {
          margin: 0px
        }
      `,
      readOnly: true,
    },
  },
};

export default daisyuiConfig;
