const sampleAppCode = `
import React from 'react';
import { Layout, Menu, Breadcrumb, Input, Button, Table, Tag, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;
const { Search } = Input;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: status => {
      let color = 'green';
      if (status === 'Unpaid') {
        color = 'volcano';
      } else if (status === 'Pending') {
        color = 'geekblue';
      }
      return (
        <Tag color={color} key={status}>
          {status.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: () => (
      <Button type="link">View</Button>
    ),
  },
];

const data = [
  {
    key: '1',
    id: '001',
    customer: 'SpaceX',
    date: 'Mar 27, 2017',
    amount: '$3719.92',
    status: 'Paid',
  },
  {
    key: '2',
    id: '002',
    customer: 'OpenAI',
    date: 'Mar 27, 2017',
    amount: '$3719.92',
    status: 'Paid',
  },
  {
    key: '3',
    id: '003',
    customer: 'Apple',
    date: 'Mar 27, 2017',
    amount: '$3719.92',
    status: 'Unpaid',
  },
  {
    key: '4',
    id: '004',
    customer: 'Microsoft',
    date: 'Mar 27, 2017',
    amount: '$3719.92',
    status: 'Paid',
  },
  {
    key: '5',
    id: '005',
    customer: 'Bytedance',
    date: 'Mar 27, 2017',
    amount: '$3719.92',
    status: 'Pending',
  },
];

const App = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <Sider theme="dark">
      <div className="logo" />
      <Menu theme="dark" defaultSelectedKeys={['2']} mode="inline">
        <Menu.Item key="1">Dashboard</Menu.Item>
        <Menu.Item key="2">Invoices</Menu.Item>
        <Menu.Item key="3">Reports</Menu.Item>
        <Menu.Item key="4">Customers</Menu.Item>
        <Menu.Item key="5">Settings</Menu.Item>
        <Menu.Item key="6">Help</Menu.Item>
      </Menu>
    </Sider>
    <Layout className="site-layout">
      <Header style={{ padding: 0, background: '#001529' }} >
        <div style={{ float: 'right', padding: '0 16px' }}>
          <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
        </div>
      </Header>
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Invoices</Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <div style={{ marginBottom: 16 }}>
            <Button type="primary" style={{ marginRight: 8 }}>
              Add invoice
            </Button>
            <Search placeholder="Search invoices" style={{ width: 200 }} />
          </div>
          <Table columns={columns} dataSource={data} />
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
    </Layout>
  </Layout>
);

export default App;
`;

const antdConfig = {
  template: "vite-react",
  customSetup: {
    dependencies: {
      antd: "latest",
      "@ant-design/icons": "latest",
    },
  },
  files: {
    "/App.jsx": {
      code: sampleAppCode,
      readOnly: true,
    },
    "/styles.css": {
      code: `
        body {
          margin: 0px
        }
      `,
      readOnly: true,
    },
  },
};

export default antdConfig;
