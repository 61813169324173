import { motion } from "framer-motion";
// import { useTranslation, useSelectedLanguage } from "next-export-i18n";
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { Button } from "@nextui-org/react"

export default function Home () {
  // i18n
  const { t } = useTranslation();
  // const currentLang = useSelectedLanguage().lang;
  // const router = useRouter();

  const [siteTitle, setSiteTitle] = useState<string>(t("site.title"));
  const [siteDes, setSiteDes] = useState<string>(t("site.des"));

  // useEffect(() => {
  //   setSiteTitle(t("site.title"));
  //   setSiteDes(t("site.des"));
  // }, [currentLang]);

  return(
   <div>
    {/* <NextSeo
      title={siteTitle}
      description={siteDes}
      canonical={`https://www.${process.env.NEXT_ROOT_URL}`}
      languageAlternates={LangForNextSEO}
      openGraph={{
        url: `https://${process.env.NEXT_ROOT_URL}`,
        title: siteTitle,
        description: siteDes,
        images: [],
        siteName: siteTitle,
      }}
    /> */}

    <div className="middle w-full h-full flex flex-col justify-center items-center mt-64 mb-44 text-white">
      <div className="w-full md:w-[600px] relative flex flex-col text-center z-10">
        <motion.div
          initial={{opacity: 0, y: 30}}
          animate={{opacity: 1, y: 0}}
          transition={{ ease: "easeOut", duration: 0.45 }}
          className="text-xl md:text-2xl lg:text-3xl font-bold">
            { t("home.title") }
        </motion.div>

        <motion.div
          initial={{opacity: 0, y: 40}}
          animate={{opacity: 1, y: 0}}
          transition={{ ease: "easeOut", duration: 0.55 }}
          className="text-md md:text-lg lg:text-xl font-normal mt-4">
            { t("home.subtitle") }
        </motion.div>
      </div>

      <motion.div
        initial={{opacity: 0, y: 30}}
        animate={{opacity: 1, y: 0}}
        transition={{ ease: "easeOut", duration: 0.75, delay: 0.4 }}
        className="mt-8 md:mt-12 z-10 flex flex-col md:flex-row gap-1 md:gap-4 justify-center items-center">
        <Button size="lg">Comming soon</Button>
      </motion.div>

      {/* <div className='middle-bg absolute top-0 left-0 right-0 w-full z-0 pointer-events-none'>
        <img src="./assets/img/bg.png" alt="background" className="w-full opacity-10" />
      </div> */}
    </div>


    <hr className="mt-12 md:mt-12 opacity-10" />

   </div>
  );
}