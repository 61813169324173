// React Router
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

// Import pages
import Home from "./pages/home";
import SignIn from "./pages/signin";
import UserCenter from "./pages/userCenter";
import Canvas from "./pages/canvas";

export const PageRouter = [
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "signin",
    element: <SignIn/>,
  },
  {
    path: "userCenter",
    element: <UserCenter/>,
  },
  {
    path: "canvas",
    element: <Canvas/>,
  },
];