import FIREBASE_APP from "../../firebase/config";
import { browserLocalPersistence, getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useState } from "react";
import { Spinner, User, Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Chip, Button, Pagination, Select, SelectItem } from "@nextui-org/react";
import { BillTableStatusColorMap } from "../../static/billTable";
import { toast } from "react-toastify";
import { ToastConfig } from "../../static/toastify/toastConfig";
import PaymentWindow from "../../components/paymentWindow";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BillCurrency, BillStatus, StandardResponse, StandardResponseWithTotal } from "../../static/types";



export interface BillData {
  platform: string,
  product: string,
  userId: string,
  transitionId: string,
  credits: number,
  currency: BillCurrency,
  price: number,
  status: BillStatus,
  ts: number,
}

const BillTableColumn = [
  // { name: "platform", label: "Platform" },
  { name: "product", label: "Product" },
  { name: "transitionId", label: "Transition Id" },
  { name: "price", label: "Price" },
  // { name: "currency", label: "Currency" },
  { name: "status", label: "Status" },
  { name: "ts", label: "Date" }
]

const BillHistoriesPageSize = 10;

export default function UserCenter () {
  const { t } = useTranslation();

  // Router
  const navigate = useNavigate();

  const [hasUserAuthInfo, setHasUserAuthInfo] = useState<boolean>(false);
  const [userImage, setUserImage] = useState<string>("");
  const [userName, setUserName] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userCurrency, setUserCurrency] = useState<string | null>(null);

  const [hasUserCreditsCurrencyInfo, setHasUserCreditsCurrencyInfo] = useState<boolean>(false);
  const [userCredits, setUserCredits] = useState<number | null>(null);

  const [hasBillInfo, setHasBillInfo] = useState<boolean>(false);
  const [billHistories, setBillHistories] = useState<BillData | null>(null);
  const [billHistoriesCurrentPageNum, setBillHistoriesCurrentPageNum] = useState<number>(0);
  const [billHistoriesTotalCount, setBillHistoriesTotalCount] = useState<number>(0);
  const [billHistoriesTotalPageCount, setBillHistoriesTotalPageCount] = useState<number>(0);

  const [isCurrencyUpdating, setIsCurrencyUpdating] = useState<boolean>(false);
  const [isSigningOut, setIsSigningOut] = useState<boolean>(false);

  const [isPaymentWindowOpen, setIsPaymentWindowOpen] = useState<boolean>(false);

  const currencyOpts = [
    { value: BillCurrency.usd, label: BillCurrency.usd.toUpperCase() },
    { value: BillCurrency.cny, label: BillCurrency.cny.toUpperCase() }
  ]

  const topup = async () => {
    setIsPaymentWindowOpen(true);
  }
  
  // Check again for auth state
  useEffect(() => {
    const auth = getAuth(FIREBASE_APP);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // https://firebase.google.com/docs/reference/js/auth.user
        setUserName(user.displayName);
        setUserEmail(user.email);
        setUserImage(user.photoURL || "");
        setHasUserAuthInfo(true);

        // Init functions
        const functions = getFunctions(FIREBASE_APP);
        if(process.env.NODE_ENV === "development") connectFunctionsEmulator(functions, "127.0.0.1", 5001);
        
        // Get credits
        const userCredits = httpsCallable(functions, 'userCredits');
        const userCreditsRes = await userCredits();
        const userCreditsResData = userCreditsRes.data as StandardResponse;
        if(userCreditsResData.status) {
          setUserCredits(userCreditsResData.data.credits);
          setUserCurrency(userCreditsResData.data.currency);
          setHasUserCreditsCurrencyInfo(true);
        }
      } else {
        navigate("/signin");
      }
    });
    auth.setPersistence(browserLocalPersistence);
  }, []);

  useEffect(() => {
    getBillHistories(BillHistoriesPageSize, billHistoriesCurrentPageNum);
  }, [billHistoriesCurrentPageNum]);

  const getBillHistories = async (pageSize: number, pageNum: number) => {
    setHasBillInfo(false);
    // Init functions
    const functions = getFunctions(FIREBASE_APP);
    if(process.env.NODE_ENV === "development") connectFunctionsEmulator(functions, "127.0.0.1", 5001);

    // Get bill history
    const billHistory = httpsCallable(functions, 'billHistory');
    const billHistoryRes = await billHistory({ pageSize, pageNum });
    const billHistoryResData = billHistoryRes.data as StandardResponseWithTotal;

    if(billHistoryResData.status) {
      setBillHistories(billHistoryResData.data);

      // Set pagination
      const totalPageCount = Math.ceil(billHistoryResData.total / pageSize);
      // console.log("totalPageCount: " + totalPageCount);
      setBillHistoriesTotalCount(billHistoryResData.total);
      setBillHistoriesTotalPageCount(totalPageCount);

      // console.log(billHistoryResData);
      setHasBillInfo(true);
    }
  }

  const renderTableCell = useCallback((data:BillData, key:string) => {
    const cellValue = data[key as keyof BillData];

    switch (key) {
      case "price":
        return cellValue + " " + data.currency.toUpperCase();
      
      case "ts":
        const tsToDate = new Date(cellValue);
        const formatted = tsToDate.toLocaleString();
        return formatted;
      
      case "status":
        // Get enum key name as value
        const indexOfValue = Object.values(BillStatus).indexOf(cellValue as unknown as BillStatus);
        const value = Object.keys(BillStatus)[indexOfValue];
        return (
          <Chip className="capitalize" color={BillTableStatusColorMap[data.status] as any} size="sm" variant="flat">
            {value}
          </Chip>
        );
      default:
        return cellValue;
    }
  }, []);

  const handlePaginationChange = (newPageNum:number) => {
    setBillHistoriesCurrentPageNum(newPageNum-1);
  }

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUserCurrency(e.target.value);
  }

  const updateCurrency = async () => {
    setIsCurrencyUpdating(true);
    // Init functions
    const functions = getFunctions(FIREBASE_APP);
    if(process.env.NODE_ENV === "development") connectFunctionsEmulator(functions, "127.0.0.1", 5001);

    // Update currency
    const userUpdateCurrency = httpsCallable(functions, 'userUpdateCurrency');
    const userUpdateCurrencyRes = await userUpdateCurrency({ currency: userCurrency });
    const userUpdateCurrencyResData = userUpdateCurrencyRes.data as StandardResponse;
    if (userUpdateCurrencyResData.status) {
      toast.success(t("user_center.updated"), ToastConfig);
    } else {
      toast.error(t("user_center.updated_error"), ToastConfig);
    }
    setIsCurrencyUpdating(false);
  }

  const signout = () => {
    const auth = getAuth(FIREBASE_APP);
    setIsSigningOut(true);
    signOut(auth).then(() => {
      setIsSigningOut(false);
      navigate("./signin");
      return;
    }).catch((error) => {
      setIsSigningOut(false);
      console.error("Fail to sign out due to internet issue. Please refresh page.")
    });
  }

  return (
    <div className="user-center mt-12">
      
      <div className="user-center-myacc">
        <div className="user-center-myacc-info">
          { hasUserAuthInfo && (
            <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center align-middle">
              <div className="flex flex-row justify-between md:justify-start items-center align-middle">
                <div>
                  <User   
                    name={userName}
                    description={userEmail}
                    avatarProps={{ src: userImage }}
                  />
                </div>
                
                <div className="ml-3">
                  <Button
                    color="danger"
                    size="sm"
                    variant="ghost"
                    onClick={signout}
                    isLoading={isSigningOut}>{t("user_center.sign_out")}</Button>
                </div>
              </div>

              <div className="mt-3 md:mt-0">
                { hasUserCreditsCurrencyInfo && (
                    <div 
                      className="flex flex-row justify-center items-center py-3 px-4 bg-slate-900 hover:bg-blue-800 border border-slate-800 hover:border-blue-400 rounded-md cursor-pointer transition"
                      onClick={topup}>
                      <div className="mr-1 mt-[0.5px]">
                        <img src="./assets/icons/addmore.svg" alt="topup icon" width="24px" />
                      </div>
                      <div>
                        <span>Credits: {userCredits}</span>
                      </div>
                    </div>
                )}
                
              </div>
            </div>
          )}
        </div>
      </div>

      <hr className="mt-6 opacity-10"/>

      <div className="mt-8">
        <div className="flex flex-row justify-start text-left items-center align-middle">
          <div className="block w-full">
            <div>
              <span className="text-2xl font-bold">{t("user_center.currency_title")}</span>
            </div>

            { hasUserCreditsCurrencyInfo ? (
              <div className="w-full">
                <div>
                  <Select
                    value={userCurrency || ""}
                    label={t("user_center.currency_title")}
                    placeholder={userCurrency?.toUpperCase() || ""}
                    className="mt-3 max-w-xs w-72"
                    onChange={handleCurrencyChange}
                  >
                    {currencyOpts.map((currency) => (
                      <SelectItem key={currency.value} value={currency.value}>
                        {currency.label}
                      </SelectItem>
                    ))}
                  </Select>
                </div>

                <div className="mt-4">
                  <Button
                    color="primary"
                    onClick={updateCurrency}
                    isLoading={isCurrencyUpdating}>
                      {t("user_center.update")}
                  </Button>
                </div>
              </div>
            ) : <Spinner color="default"/>}
          </div>
        </div>
      </div>

      {/* <hr className="mt-6 opacity-10"/> */}

      <div className="mt-8">
        <div className="flex flex-row justify-between items-center align-middle">
          <div className="flex justify-start items-end">
            <div>
              <span className="text-2xl font-bold">{t("user_center.bills_title")}</span>
            </div>

            {/* loading */}
            {!hasBillInfo && (
              <div>
                <span className="text-sm opacity-30 ml-3">{t("user_center.bills_loading")}</span>
              </div>
            )}

            {/* no record */}
            {(billHistoriesTotalCount <= 0 && hasBillInfo) && (
              <div>
                <span className="text-sm opacity-30 ml-3">{t("user_center.bills_empty")}</span>
              </div>
            )}

            {/* has records, display count */}
            { billHistoriesTotalCount > 0 && (
              <div>
                <span className="text-sm opacity-30 ml-3">{t("user_center.bills_total", { count:  billHistoriesTotalCount })}</span>
              </div>
            )}
          </div>
          <div>
            {(hasBillInfo && billHistoriesTotalPageCount > 1) && (<Pagination
              total={ billHistoriesTotalPageCount }
              page={ billHistoriesCurrentPageNum + 1 }
              onChange={ handlePaginationChange }
            />)}
          </div>
        </div>

        <div className="mt-3">
          { hasBillInfo ? (
            <Table aria-label="Example table with custom cells">
              <TableHeader columns={BillTableColumn}>
                {(column) => (
                  <TableColumn key={column.name} align="start">
                    {column.label}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody items={billHistories as any}>
                {(item: BillData) => (
                  <TableRow key={item.transitionId}>
                    {(columnKey) => <TableCell>{renderTableCell(item, columnKey as string)}</TableCell>}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : <Spinner color="default"/>}
        </div>
      </div>

      <PaymentWindow isRanOut={false} isOpen={isPaymentWindowOpen} onClosed={() => setIsPaymentWindowOpen(false)}/>

    </div>
  );
}