export const LINKER_REF = "?ref="+process.env.REACT_APP_ROOT_URL;
export default function Linker () {
  const sites = [
    { href: "https://varys.ai", title: "Varys AI - interior design AI for professional, GPT for room and space" },
    { href: "https://lite.varys.ai", title: "Varys AI Lite - Take a picture of a room and instantly redesign it in 20+ different themes. No more guesswork, no more costly mistakes." },
    { href: "https://ai-architect.net/", title: "Architect AI - AI Architecture Renderer, GPT for Architecture Design Idea Inspiration" },
    { href: "https://ai-building.app/", title: "Architect AI - AI Architecture Renderer, GPT for Architecture Design Idea Inspiration" },
    { href: "https://ecommerce-ai.net/", title: "E-Commerce AI - Create professional e-commerce product photo for free with seconds" },
    { href: "https://interior-render.com/", title: "Interior Render AI - Any can re-design your room in seconds by photo, interior design by AI, get design inspiration for designers by GPT" },
    { href: "https://launchai.app/", title: "Launch AI - Launch your AI App" },
    { href: "https://ailauncher.app/", title: "AI Launcher - Launch your AI App" },
    { href: "https://game-generator.com/", title: "AI Launcher - Launch your AI App" },
    { href: "https://ailauncher.app/", title: "AI Game Assets Generator - generate free game assets in seconds by AI, integrate AI workflow in your game, GPT for game development" },
    { href: "https://erase-background.com/", title: "Erase Background From Image - Remove Image Background By AI" },
    { href: "https://web-design-ai.com/", title: "AI App Designer - Design Web App with Design Systems by AI" }
  ];

  return (
    <div className="absolute bottom-0 flex flex-wrap text-sm h-1" style={{zIndex: -1, color: "black", opacity: 0.01}}>
      {sites.map((site) => {
        return <div key={site.href}><a href={site + LINKER_REF}>{site.title}</a></div>;
      })}
    </div>
  );
}