import { useEffect } from 'react';

// 100% By GPT

const useDisableTouchpadBackGesture = () => {
    useEffect(() => {
        let touchStartX = 0;

        const handleTouchStart = (e: TouchEvent) => {
            touchStartX = e.changedTouches[0].screenX;
        };

        const handleTouchMove = (e: TouchEvent) => {
            const touchEndX = e.changedTouches[0].screenX;
            const deltaX = touchEndX - touchStartX;

            // 如果是水平滑动且滑动距离超过一定阈值，则阻止默认行为
            if (Math.abs(deltaX) > 50) {
                e.preventDefault();
            }
        };

        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove, { passive: false });

        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);
};

export default useDisableTouchpadBackGesture;
