import { Tldraw, setUserPreferences } from "@tldraw/tldraw";
import "@tldraw/tldraw/tldraw.css";
import { useEffect, useState, useMemo } from "react";
import { PreviewShapeUtil } from "./PreviewShape/PreviewShape";
import { ExportButton } from "./CanvasComponents/ExportButton";
import { useNavigate } from "react-router-dom";
import FIREBASE_APP from "../../firebase/config";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import SandBoxPreview from "./CanvasComponents/SandBoxPreview";
import materialUiConfig from "./CanvasComponents/config/materialUiConfig";
import daisyuiConfig from "./CanvasComponents/config/daisyuiConfig";
import antdConfig from "./CanvasComponents/config/antdConfig";
import { Spinner } from "@nextui-org/react";
import useDisableTouchpadBackGesture from "../../utils/useDisableTouchpadBackGesture";
import { SandpackProvider } from "@codesandbox/sandpack-react";

enum ViewType {
  Full,
  Split,
}

export default function Canvas() {
  const [isMakeRealLoading, setIsMakeRealLoading] = useState<boolean>(false);
  const [reactCode, setReactCode] = useState<any>(null);
  const [sandpackConfig, setSandpackConfig] = useState<any>(null);
  const [designSystem, setDesignSystem] = useState<string>("materialUi");
  const [hasNew, setHasNew] = useState<boolean>(false);
  const [currentViewType, setCurrentViewType] = useState<ViewType>(
    ViewType.Full
  );

  setUserPreferences({ id: "ux-designer", isDarkMode: true });

  // Router
  const navigate = useNavigate();

  // Check again for auth state
  useEffect(() => {
    const auth = getAuth(FIREBASE_APP);
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate("/signin");
      }
    });
  }, []);

  useEffect(() => {
    if (designSystem === "materialUi") {
      setSandpackConfig(materialUiConfig);
    } else if (designSystem === "daisyui") {
      setSandpackConfig(daisyuiConfig);
    } else if (designSystem === "antd") {
      setSandpackConfig(antdConfig);
    }
  }, [designSystem]);

  const shapeUtils = useMemo(() => [PreviewShapeUtil], []);

  const handleLoadingChange = (state: boolean) => {
    setIsMakeRealLoading(state);
    if (state && currentViewType === ViewType.Full) {
      setHasNew(true);
    }
  };

  const handleOpenResultContainer = () => {
    setCurrentViewType(
      currentViewType === ViewType.Full ? ViewType.Split : ViewType.Full
    );
    setHasNew(false);
  };

  useDisableTouchpadBackGesture();

  return (
    <div className="fixed w-full h-[100vh] flex">
      {/* <Button onClick={() => setCurrentViewType(currentViewType === ViewType.Full ? ViewType.Split : ViewType.Full)}>Switch View</Button> */}
      <div className="w-full z-20">
        <Tldraw
          persistenceKey="ux-designer"
          shapeUtils={shapeUtils}
          shareZone={
            <ExportButton
              setReactCode={setReactCode}
              designSystem={designSystem}
              setDesignSystem={setDesignSystem}
              onLoadingChange={handleLoadingChange}
            />
          }
        ></Tldraw>
      </div>

      <div
        className={`fixed right-60 top-[10.5vh] bottom-0 h-[89vh] w-2/5 rounded-lg border-2 border-white/10 shadow-xl overflow-hidden transition z-30 ${
          currentViewType === ViewType.Full
            ? "translate-x-[1000px] opacity-0 pointer-events-none"
            : "translate-x-[0px] opacity-100 pointer-events-auto"
        }`}
      >
        {/* Full screen loading */}
        {isMakeRealLoading && (
          <div className="absolute w-full h-full top-0 left-0 bg-black/70 backdrop-blur-lg">
            <Spinner size="sm" />
          </div>
        )}
        {sandpackConfig && (
          <SandpackProvider
            key={designSystem}
            style={{
              transform: "scale(0.5)",
              transformOrigin: "top left",
              width: "200%",
              height: "200%",
            }}
            template={sandpackConfig.template}
            customSetup={sandpackConfig.customSetup}
          >
            <SandBoxPreview reactCode={reactCode} designSystem={designSystem} />
          </SandpackProvider>
        )}
      </div>

      <div className="fixed right-44 top-[45vh] w-12 h-12 cursor-pointer z-40">
        <div
          className="flex justify-center items-center w-full h-full border-1 border-white/5 bg-[rgba(31,32,35,.85)] hover:bg-white/10 shadow-sm hover:shadow-xl transition bg-opacity-70  backdrop-blur-lg border-opacity-100 rounded-full"
          onClick={handleOpenResultContainer}
        >
          {isMakeRealLoading && <Spinner size="sm" color="default" />}
          {!isMakeRealLoading && (
            <div className="flex w-full h-full justify-center items-center">
              <img
                className="w-1/2 h-1/2"
                src="/assets/icons/codepen.svg"
                alt="code and page view icon"
              />
              {hasNew && (
                <div className="bg-blue-600 w-2 h-2 absolute right-3 top-2 rounded-full"></div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
