import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import FIREBASE_APP from "./config";
import { StandardResponse } from "../static/types";

export const topupCredits = async (productId: string):Promise<null | string> => {
  const functions = getFunctions(FIREBASE_APP);
  if(process.env.NODE_ENV === "development") connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  
  const stripePaySession = httpsCallable(functions, "stripePaySession");
  const startPaySession = await stripePaySession({ productId });
  const startPaySessionData = startPaySession.data as StandardResponse;

  if(startPaySessionData.status) {
    // window.open(startPaySessionData.data, "_blank");
    return startPaySessionData.data as string;
  } else {
    return null;
  }
}

export const getUserCredits = async () => {
  const functions = getFunctions(FIREBASE_APP);
  if(process.env.NODE_ENV === "development") connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  const userCredits = httpsCallable(functions, "userCredits");
  const userCreditsRes = await userCredits();
  const userCreditsResData = userCreditsRes.data as StandardResponse;
  
  return userCreditsResData.data.credits;
}

export const getUserPaymentInfo = async () => {
  const functions = getFunctions(FIREBASE_APP);
  if(process.env.NODE_ENV === "development") connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  const userCredits = httpsCallable(functions, 'userCredits');
  const userCreditsRes = await userCredits();
  const userCreditsResData = userCreditsRes.data as StandardResponse;
  
  return userCreditsResData.data;
}