import { useEffect, useState } from 'react';
import './App.css';
import { browserLocalPersistence, getAuth, onAuthStateChanged } from "firebase/auth";
import FIREBASE_APP from "./firebase/config";
import { NextUIProvider } from "@nextui-org/react";
import { PageRouter } from './router';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Navbar from './components/navbar';
import Footer from './components/footer';
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { AuthInfoProps, StandardResponse } from './static/types';

import { store } from './store/store';
import { Provider as StoreProvider } from "react-redux";

function App() {
  const [isAuthing, setIsAuthing] = useState<boolean>(false);
  const [authInfo, setAuthInfo] = useState<AuthInfoProps>({
    isAuth: false,
    userImage: null,
    userCredits: null,
    userId: null,
    userEmail: null,
    userAll: null
  });

  useEffect(() => {
    setIsAuthing(true);

    const auth = getAuth(FIREBASE_APP);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const functions = getFunctions(FIREBASE_APP);
        if(process.env.NODE_ENV === "development") connectFunctionsEmulator(functions, "127.0.0.1", 5001);
        const userCredits = httpsCallable(functions, 'userCredits');
        const userCreditsRes = await userCredits();
        const userCreditsResData = userCreditsRes.data as StandardResponse;
        
        setAuthInfo({
          isAuth: true,
          userImage: user.photoURL,
          userCredits: userCreditsResData.data.credits,
          userId: user.uid,
          userEmail: user.email,
          userAll: user
        });
      }
      
      setIsAuthing(false);
    });
    auth.setPersistence(browserLocalPersistence);
  }, []);

  return (
    <div className="App dark w-full p-0">
      <NextUIProvider>
        <Router>
      
        <main>
          <StoreProvider store={store}>
          <Navbar
            auth={authInfo}
            isAuthing={isAuthing}
            productHuntLink=''/>

          {/* <RouterProvider router={ PageRouter } /> */}
          <Routes>
            { PageRouter.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>

          <Footer></Footer>
          <ToastContainer/>
          </StoreProvider>

        </main>
    
      </Router>
    </NextUIProvider>

    </div>
  );
}

export default App;
