import { AnimationProps, motion } from "framer-motion";
import { ReactNode } from "react";

const ANI_TIME_TEXT_APPEAR = 0.32;

export const AnimatedText = ({
  text,
  delay,
  textStyle,
}: {
  text: string;
  delay?: number;
  textStyle?: any;
}) => {
  const words = text.split(" ");

  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.12, delayChildren: 0.04 * i + (delay || 0) },
    }),
  };

  const transition: AnimationProps["transition"] = {
    duration: ANI_TIME_TEXT_APPEAR, type: "tween", ease: 'easeInOut'
  };

  const child = {
    visible: {
      opacity: 1,
      y: 0,
      transition,
    },
    hidden: {
      opacity: 0,
      y: 10,
      transition,
    },
  };

  return (
    <motion.div
      className="flex overflow-hidden flex-wrap leading-[1.17]"
      variants={container}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      {words.map((word, index) => (
        <motion.span variants={child} style={textStyle} className="mr-2" key={index}>
          {word}
        </motion.span>
      ))}
    </motion.div>
  );
};

export const MotionRevealUp = ({
  children,
  delay,
  className,
}: {
  children: ReactNode;
  delay?: number;
  className?: string;
}) => {
  return (
    <motion.div
      className={className}
      initial={{ y: 10, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: delay || 0, duration: ANI_TIME_TEXT_APPEAR, type: "tween", ease: 'easeInOut' }}
    >
      {children}
    </motion.div>
  );
};
