import {
  SandpackLayout,
  SandpackPreview,
  SandpackCodeEditor,
} from "@codesandbox/sandpack-react";
import { useEffect, useState } from "react";
import { useSandpack } from "@codesandbox/sandpack-react";
import daisyuiConfig from "./config/daisyuiConfig";
import antdConfig from "./config/antdConfig";
import materialUiConfig from "./config/materialUiConfig";

const SandBoxPreview = (props: any) => {
  const { reactCode, designSystem } = props;
  const { dispatch, listen, sandpack } = useSandpack();

  const [isSandPackLoading, setIsSandPackLoading] = useState(true);
  const [isPreviewOpen, setIsPreviewOpen] = useState(true);

  useEffect(() => {
    // listens for any message dispatched between sandpack and the bundler
    const stopListening = listen((msg) => {
      if (msg.type === "connected") {
        setIsSandPackLoading(true);
        // console.log(msg);
      } else if (msg.type === "done") {
        setIsSandPackLoading(false);
        // console.log(msg);
      }
      console.log(msg);
    });

    return () => {
      // unsubscribe
      stopListening();
    };
  }, [listen]);

  useEffect(() => {
    if (isSandPackLoading === false) {
      if (designSystem === "materialUi") {
        sandpack.updateFile(materialUiConfig.files);
      } else if (designSystem === "daisyui") {
        sandpack.updateFile(daisyuiConfig.files);
      } else if (designSystem === "antd") {
        sandpack.updateFile(antdConfig.files);
      }
    }
  }, [isSandPackLoading]);

  useEffect(() => {
    if (reactCode !== null) {
      sandpack.updateFile("/App.jsx", reactCode);
    }
  }, [reactCode]);

  const handleToggle = () => {
    setIsPreviewOpen((prev) => {
      return !prev;
    });
  };

  return (
    <div className="h-full relative">
      <button
        className="bg-gray-500 hover:bg-gray-700 text-white py-1 px-4 rounded text-sm absolute z-50 bottom-2 left-2 w-32"
        onClick={handleToggle}
        style={{ backgroundColor: isSandPackLoading ? "red" : "green" }}
      >
        {isPreviewOpen ? "Code Editor" : "Live Preview"}
      </button>

      <SandpackLayout style={{ height: "100%" }}>
        <SandpackCodeEditor
          style={{
            height: "100%",
            display: isPreviewOpen ? "none" : "flex",
          }}
        />
        <SandpackPreview
          style={{
            height: "100%",
            display: isPreviewOpen ? "flex" : "none",
          }}
        />
      </SandpackLayout>
    </div>
  );
};

export default SandBoxPreview;
