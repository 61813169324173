/** eslint-disable */
import { User } from "firebase/auth";
export interface AuthInfoProps {
  isAuth: boolean,
  userImage: string | null,
  userCurrency?: string | null,
  userCredits: number | null,
  userId: string | null,
  userEmail: string | null,
  userAll: User | null
}

export interface StandardResponse {
  status: boolean,
  msg: string,
  data: any
}

export interface StandardResponseWithTotal extends StandardResponse {
  total: number,
}

export enum GenerateType{
  renderer = "renderer",
  inspire = "inspire"
}

export interface FeedbackData {
  rate: number,
  details: string,
}

export enum BillCurrency {
  usd = "usd",
  cny = "cny",
  gbp = "gbp",
  eur = "eur",
  jpy = "jpy",
  hkd = "hkd",
  rub = "rub",
  sdg = "sdg",
}

export enum BillStatus {
  Waiting = 1,
  Done = 10,
  Expired = 90,
  Failed = 99
}