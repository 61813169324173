import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { LS_KEY_LANGSET } from "../static/localstorageState";


export const SupportedLanguage = [
  { name: "English", code: "en" },
  { name: "简体中文", code: "zh" },
  { name: "繁体中文", code: "zh-TW" },
  { name: "Deutsch", code: "de" },
  { name: "Spanish", code: "es" },
  { name: "Russian", code: "ru" },
  { name: "日本語", code: "ja" },
  { name: "French", code: "fr" },
  { name: "Bahasa Indonesia", code: "id" },
  { name: "Italiano", code: "it" },
];

// import Image from 'next/image'
export default function Footer () {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const switchLang = (lang: string) => {
    localStorage.setItem(LS_KEY_LANGSET, lang);
    setSearchParams({ lang });
  };

  const isArticlePage = location.pathname.includes("article");
  const isCanvasPage = location.pathname.includes("canvas");

  const renderLangs = () => {
    return (
      <div className="w-full text-center flex flex-row flex-wrap gap-8 text-xs justify-center items-center">
        { !isArticlePage && !isCanvasPage && SupportedLanguage.map((el) => <div className="cursor-pointer" onClick={() => switchLang(el.code)} key={el.code}>{el.name}</div>) }
      </div>
    );
  }

  const year =  new Date().getFullYear();
  return (
    <div className="footer flex flex-col w-full items-center justify-between font-mono text-sm">
      <div className="footer-lang-switcher w-full mt-24 mb-6">{renderLangs()}</div>
      <div className="w-full flex justify-center items-center align-middle text-center h-24 mb-12">
        XLLab AI Development. Copyright © {year} XLLab. All rights reserved.
      </div>
    </div>
  )
}