// Redux Store
import { configureStore } from '@reduxjs/toolkit';
import currencySlice from './features/currency/currencySlice';

export const store = configureStore({
  reducer: { 
    userCurrency: currencySlice
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
