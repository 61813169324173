import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import FIREBASE_APP from "../../../firebase/config";
import { StandardResponse } from "../../../static/types";

export async function getHtmlFromOpenAI({
  image,
  text,
  designSystem,
  theme = "light",
}: {
  image: string;
  text: string;
  designSystem: string;
  theme?: string;
}) {
  // Init functions
  const functions = getFunctions(FIREBASE_APP);
  if (process.env.NODE_ENV === "development")
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);

  // Get bill history
  const getHTML = httpsCallable(functions, "getHtmlFromOpenAI");
  const getHTMLRes = await getHTML({
    image,
    text,
    designSystem,
    theme,
  });

  return getHTMLRes.data as StandardResponse;
}
