
import FIREBASE_APP from "../../firebase/config";
import { Image } from "@nextui-org/react";
import { getAuth, signInWithPopup, browserLocalPersistence, GoogleAuthProvider } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const provider = new GoogleAuthProvider();

export default function SignIn() {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const signIn = () => {
    // alert("Comming soon...")
    const auth = getAuth(FIREBASE_APP);
    signInWithPopup(auth, provider)
    .then((result) => {
      navigate("/")
    }).catch((error) => {
      
    });

    auth.setPersistence(browserLocalPersistence);
  }

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center align-middle">
      <div className="flex flex-col justify-center items-center mt-[-160px] w-full md:w-3/4 z-10">
        <div className="text-center">
          <div className="text-[72px] font-bold">👨🏻‍💻</div>
          <div className="text-2xl md:text-4xl font-bold">{t("signin.title")}</div>
          <div className="text-md md:text-xl opacity-90 mt-1 mb-6">{t("signin.subtitle")}</div>
        </div>
        
        <div className="cursor-pointer w-48 drop-shadow opacity-95 hover:opacity-100 hover:scale-105 hover:drop-shadow-2xl transition z-10">
          <Image onClick={signIn} src="./assets/icons/google-signin.png" />
        </div>
      </div>
    </div>
  );
}