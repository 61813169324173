import { useEffect, useState } from "react";
import { useEditor, useToasts } from "@tldraw/tldraw";
import { makeReal } from "../lib/makeReal";
import { Button, Select, SelectItem } from "@nextui-org/react";

export function ExportButton(props: any) {
  const { setReactCode, designSystem, setDesignSystem } = props;
  const editor = useEditor();

  const [isLoading, setIsLoading] = useState(false);

  const handleChangeSelection = (e: any) => {
    const selectionValue = e.target.value;
    if (selectionValue) {
      // setReactCode(null);
      setDesignSystem(e.target.value);
    }
  };

  const handleExport = async () => {
    setIsLoading(true);
    props.onLoadingChange(true);
    try {
      const reactCode = await makeReal(editor, designSystem);
      setReactCode(reactCode);
    } catch (e: any) {
      console.error(e);
    }
    setIsLoading(false);
    props.onLoadingChange(false);
  };

  const selectOptions = [
    {
      label: "Material UI",
      value: "materialUi",
    },
    {
      label: "daisyUI",
      value: "daisyui",
    },
    {
      label: "Ant Design",
      value: "antd",
    },
  ];

  return (
    <div
      style={{
        zIndex: 100000,
        pointerEvents: "all",
        width: "100%",
        padding: "8px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "8px",
          backgroundColor: "var(--color-panel)",
          borderRadius: "var(--radius-3)",
          boxShadow: "var(--shadow-2)",
        }}
      >
        <Select
          selectedKeys={[designSystem]}
          size="sm"
          label="Design System"
          labelPlacement="outside"
          onChange={handleChangeSelection}
        >
          {selectOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </Select>
        <Button
          color="primary"
          onClick={handleExport}
          isLoading={isLoading}
          size="sm"
        >
          {isLoading ? "Loading" : "Make Real"}
        </Button>
      </div>
    </div>
  );
}
