import { BillCurrency } from '../../../static/types'; // /static/types
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface CurrencyState {
  value: BillCurrency
}

const initialState: CurrencyState = {
  value: BillCurrency.usd,
}

export const currencySlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setStoreUserCurrency: (state, action: PayloadAction<BillCurrency>) => {
      state.value = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setStoreUserCurrency } = currencySlice.actions;

export default currencySlice.reducer;