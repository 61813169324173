import {
  Modal, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Card,
  CardBody,
  Image,
  Button,
  Spinner,
  Link
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { topupCredits } from '../firebase/funcs';
import { useTranslation } from "react-i18next";
import { BillCurrency } from "../../src/static/types";

enum PaymentStep {
  ready = 1,
  waiting = 2,
  finished = 3
}

interface PaymentWindowProps {
  isOpen: boolean,
  onClosed: () => void,
  isRanOut: boolean,
}

interface BillProduct {
  credits: number,
  product: string, // id from stripe
  price: {
    [BillCurrency.usd]: number,
    [BillCurrency.cny]: number,
    [BillCurrency.gbp]: number,
    [BillCurrency.eur]?: number,
    [BillCurrency.hkd]?: number,
    [BillCurrency.rub]?: number,
    [BillCurrency.jpy]?: number,
    [BillCurrency.sdg]?: number
  }
}

interface BillProductFront extends BillProduct {
  icon: string,
}

type BillProductsList = Array<BillProductFront>;
const ProductList: BillProductsList = [
  {
    credits: 10,
    product: "price_1OM5ThApMVNtKE9cHn59EQZe",
    price: {
      [BillCurrency.usd]: 5.99,
      [BillCurrency.cny]: 41.99,
      [BillCurrency.gbp]: 4.99,
      [BillCurrency.eur]: 5.59,
      [BillCurrency.hkd]: 56.99,
      [BillCurrency.jpy]: 869.00,
      [BillCurrency.rub]: 551.99,
      [BillCurrency.sdg]: 7.99,
    },
    icon: "./assets/icons/credits10.png"
  },
  {
    credits: 30,
    product: "price_1OM5VUApMVNtKE9cz6oBaiT4",
    price: {
      [BillCurrency.usd]: 17.99,
      [BillCurrency.cny]: 128.99,
      [BillCurrency.gbp]: 14.99,
      [BillCurrency.eur]: 16.99,
      [BillCurrency.hkd]: 140.99,
      [BillCurrency.jpy]: 2609.00,
      [BillCurrency.rub]: 1659.99,
      [BillCurrency.sdg]: 23.99,
    },
    icon: "./assets/icons/credits30.png"
  },
  {
    credits: 50,
    product: "price_1OM5XbApMVNtKE9clQgDzAcq",
    price: {
      [BillCurrency.usd]: 28.99,
      [BillCurrency.cny]: 199.99,
      [BillCurrency.gbp]: 22.99,
      [BillCurrency.eur]: 26.99,
      [BillCurrency.hkd]: 229.99,
      [BillCurrency.jpy]: 4199.00,
      [BillCurrency.rub]: 2679.99,
      [BillCurrency.sdg]: 39.99,
    },
    icon: "./assets/icons/credits50.png"
  },
];

const paymentMethodLogos = [
  "./assets/icons/payments/visa.svg",
  "./assets/icons/payments/mastercard.svg",
  "./assets/icons/payments/amex.svg",
  "./assets/icons/payments/paypal.svg",
  "./assets/icons/payments/unionpay.svg",
  "./assets/icons/payments/applepay.svg",
  "./assets/icons/payments/googlepay.svg",
  "./assets/icons/payments/wechatpay.svg",
  "./assets/icons/payments/alipay.svg",
];

export default function PaymentWindow (props: PaymentWindowProps) {
  const { t, i18n } = useTranslation();
  // const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreatingPaymentLink, setIsCreatingPaymentLink] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStep>(PaymentStep.ready);

  const [paymentLink, setPaymentLink] = useState<string | null>(null);

  // Listen to inside close event
  const handleOpenChange = (isOpen:boolean) => {
    setIsModalOpen(isOpen);
    props.onClosed();
  }

  // Listen to outside open event
  useEffect(() => {
    setIsModalOpen(props.isOpen);
  }, [props.isOpen]);

  const topup = async (productId: string) => {
    if(isCreatingPaymentLink) return;

    setIsCreatingPaymentLink(true);

    const payLink = await topupCredits(productId);
    if(!payLink) {
      alert(t("fail_get_link"));
      setPaymentStatus(PaymentStep.ready);
      return
    }

    setPaymentLink(payLink);

    setIsCreatingPaymentLink(false);
    // Display confirm page
    setPaymentStatus(PaymentStep.waiting);
  }

  const payAtStripe = () => {
    if(paymentLink) {
      window.open(paymentLink);
    }
    setPaymentStatus(PaymentStep.finished);
    setPaymentLink(null); // expire payment link
  }

  const paymentDone = () => {
    window.location.reload();
    setPaymentStatus(PaymentStep.ready);
    setPaymentLink(null); // expire payment link
  }

  const paymentCancelled = () => {
    setPaymentStatus(PaymentStep.ready);
    setPaymentLink(null);
  }

  const currentLang = i18n.language;

  const renderBeforePay = () => {
    return (
      <>
        <ModalBody>
          <div className="flex flex-col mb-1">
            <div className="text-xl font-bold">  { props.isRanOut ? "🚀 " + t("alert.need_topup") : "🏆 " + t("payment_window.title") }</div>
            <div className="flex flex-row justify-start items-center">
              <div className="mr-4 flex justify-start items-center">
                <div className="text-sm opacity-70">
                  {t("payment_window.subtitle")}
                </div>

                { currentLang === "zh"  ? <Link className="opacity-50 text-xs underline text-white ml-2 cursor-pointer" href="./userCenter">货币为人民币时可使用支付宝等</Link> : null }

                <div className="w-16 absolute right-7 mt-[-24px]">
                  <img src="./assets/icons/sales-label.svg" alt="on sale 50% off" className="w-full" />
                </div>
              </div>
              
              {isCreatingPaymentLink &&  <Spinner size="sm"/>}
            </div>
          </div>

          <div className="w-full flex flex-col gap-3">
            { ProductList.map(product => 
              <div key={product.product} className="w-full">
                <Card className="w-full" isHoverable={true} isPressable={true} isDisabled={isCreatingPaymentLink} onClick={() => topup(product.product)}>
                  <CardBody>
                    <div className="product-s w-full flex justify-between items-center">
                      <div className="product-s-left flex justify-start items-center">
                        <div className="product-s-icon w-16 h-16 mr-6">
                          <Image src={product.icon}></Image>
                        </div>

                        <div className="product-s-info">
                          <div className="text-lg font-bold">{`Credits ${product.credits}`}</div>
                          <div className="text-sm opacity-60">{`${product.price[BillCurrency.usd]} ${BillCurrency.usd.toString().toUpperCase()}`}</div>
                          <div className="text-sm opacity-60">{`${product.price[BillCurrency.gbp]} ${BillCurrency.gbp.toString().toUpperCase()}`}</div>
                          <div className="text-sm opacity-60">{`${product.price[BillCurrency.cny]} ${BillCurrency.cny.toString().toUpperCase()}`}</div>
                        </div>
                      </div>
                      
                      <div className="product-s-right product-s-topup mr-2">
                        {/* <Button color="warning">Top-up</Button> */}
                        <div className="text-blue-500">{t("payment_window.topup")}</div>
                      </div>
                      
                    </div>
                  </CardBody>
                </Card>
              </div>
            )}
          </div>

          <div>
            <div className="flex justify-center items-center text-sm">
              <span className="opacity-90">{t("payment_window.notice.secure_by")}</span>
              <img className="mx-1 mt-0.5" src="./assets/icons/stripe.svg" alt="stripe logo" width={40} />
              <span className="opacity-90">{t("payment_window.notice.platform")}</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>

        </ModalFooter>
      </>
    )
  }

  const renderInPay = () => {
    return (
      <>
        <ModalBody>
          <div>
            <div className="flex justify-start items-center text-xl font-bold">
              <span className="opacity-90">{t("payment_window.in_pay.title")}</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="flex-col">
          <div className="w-full">
            <div className="flex justify-start items-center text-left text-sm">
              <span className="opacity-90">{t("payment_window.in_pay.guide")}</span>
            </div>

            <Button className="w-full mt-5" color="primary" onClick={payAtStripe}>
              <span className="font-bold">{t("payment_window.in_pay.btn_continue")}</span>
              <img className="mt-0.5" src="./assets/icons/stripe_w.svg" alt="stripe logo" width={50} />
            </Button>

            <div className="payment-methods-list mt-2 w-full flex justify-center items-center gap-1">
              {paymentMethodLogos.map(el => 
                <div className="payment-methods-list-s w-8 h-8" key={el}>
                  <img src={el} alt="payment methods logo" className="w-full"/>
                </div>
              )}
            </div>

            {/* <div className="w-full mt-4">
              <Button className="w-full" color="default" variant="ghost" onClick={paymentCancelled}>{t("payment_window.after_pay.btn_try_again")}</Button>
            </div> */}
          </div>

          {/* <div className="w-full">
            <Button className="w-full" color="default" onClick={() => setIsPaymentWaitConfirm(false)}>{t("payment_window.after_pay.btn_try_again")}</Button>
          </div> */}
        </ModalFooter>
      </>
    )
  }

  const renderAfterPay = () => {
    return (
      <>
        <ModalBody>
          <div>
            <div className="flex justify-start items-center text-xl font-bold">
              <span className="opacity-90">{t("payment_window.after_pay.finish")}</span>
              <img className="mx-1.5 mt-0.5" src="./assets/icons/stripe.svg" alt="stripe logo" width={50} />
            </div>
            <div className="flex justify-center items-center text-left text-sm mt-2">
              <span className="opacity-90">{t("payment_window.after_pay.guide")}</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="flex-col">
          <div className="w-full">
            <Button className="w-full" color="primary" onClick={paymentDone}>{t("payment_window.after_pay.btn_done")}</Button>
          </div>

          <div className="w-full">
            <Button className="w-full" color="default" onClick={paymentCancelled}>{t("payment_window.after_pay.btn_try_again")}</Button>
          </div>
        </ModalFooter>
      </>
    )
  }

  const renderModalContent = () => {
    switch (paymentStatus) {
      case PaymentStep.ready:
        return renderBeforePay()
      case PaymentStep.waiting: 
        return renderInPay()
      case PaymentStep.finished:
        return renderAfterPay()
      default:
        return renderBeforePay()
    }
  } 

  return (
    <>
      <Modal isOpen={isModalOpen} onOpenChange={handleOpenChange} isDismissable={false}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1"></ModalHeader>
              {renderModalContent()}
            </>
          )}
          </ModalContent>
      </Modal>
    </>
  )
}