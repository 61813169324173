import { Editor, getSvgAsImage } from "@tldraw/tldraw";
import { getHtmlFromOpenAI } from "./getHtmlFromOpenAI";
import { StandardResponse } from "../../../static/types";

export async function makeReal(editor: Editor, designSystem: string) {
  const selectedShapes = editor.getSelectedShapes();

  if (selectedShapes.length === 0) {
    alert("Select elements before make real")
    throw Error("First select something to make real.");
  }

  const svg = await editor.getSvg(selectedShapes);
  if (!svg) throw Error("Could not get the SVG.");

  const IS_SAFARI = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const blob = await getSvgAsImage(svg, IS_SAFARI, {
    type: "png",
    quality: 1,
    scale: 1,
  });

  const dataUrl = await blobToBase64(blob!);

  const textFromShapes = getSelectionAsText(editor);
  try {
    const fetchOpenAI: StandardResponse = await getHtmlFromOpenAI({
      image: dataUrl,
      text: textFromShapes,
      designSystem,
      theme: editor.user.getUserPreferences().isDarkMode ? "dark" : "light",
    });

    if (!fetchOpenAI.status) {
      console.error("error on request to OpenAI: " + fetchOpenAI.msg);
      return;
    }

    const json = fetchOpenAI.data;

    if (json.error) {
      throw Error(`${json.error.message?.slice(0, 100)}...`);
    }

    const message = json.choices[0].message.content;
    console.log("answer: ", message); // print out original result

    // process result to get jsx
    const outputString = message.substring(message.indexOf("jsx") + 3);
    const reactCode = outputString.substring(0, outputString.indexOf("```"));
    console.log("reactCode: ", reactCode); // print out jsx

    return reactCode;
  } catch (e) {
    throw e;
  }
}

export function blobToBase64(blob: Blob): Promise<string> {
  // eslint-disable-next-line
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

function getSelectionAsText(editor: Editor) {
  const selectedShapeIds = editor.getSelectedShapeIds();
  const selectedShapeDescendantIds =
    editor.getShapeAndDescendantIds(selectedShapeIds);

  const texts = Array.from(selectedShapeDescendantIds)
    .map((id) => {
      const shape = editor.getShape(id);
      if (!shape) return null;
      if (
        shape.type === "text" ||
        shape.type === "geo" ||
        shape.type === "arrow" ||
        shape.type === "note"
      ) {
        // @ts-expect-error
        return shape.props.text;
      }
      return null;
    })
    .filter((v) => v !== null && v !== "");

  return texts.join("\n");
}
